import React, { FC } from "react";
import classNames from "clsx";

type TagProps = {
  link: string;
  className?: string;
};

const Tag: FC<TagProps> = ({ link, children, className }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={classNames(
      "inline-flex",
      "text-center text-gray-100 text-xs",
      "py-1 px-3 rounded-[.625rem]",
      "transition duration-150 ease-in-out",
      "bg-brand-tertiary/10",
      "hover:bg-brand-darklight hover:bg-opacity-100 hover:shadow-buttonsecondary",
      "focus:bg-brand-darklight focus:bg-opacity-100 focus:shadow-buttonsecondary",
      "active:bg-brand-tertiary active:text-brand-light/60 active:bg-opacity-10 active:shadow-none",
      className
    )}
  >
    {children}
  </a>
);

export default Tag;
