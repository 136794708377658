import React, { FC } from "react";
import classNames from "clsx";

import { MEDIUM_URL } from "../constants";
import Tag from "./Tag";

type BlogArticleProps = {
  link: string;
  image: string;
  title: string;
  subtitle: string;
  date: string;
  tags: {
    slug: string;
    name: string;
  }[];
  className?: string;
};

const BlogArticle: FC<BlogArticleProps> = ({
  link,
  image,
  title,
  subtitle,
  date,
  tags,
  className,
}) => (
  <article
    className={classNames("flex flex-col h-full", className)}
    data-aos="fade-up"
  >
    <header>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="block mb-6"
      >
        <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
          <img
            className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
            src={image}
            alt={subtitle}
            width="352"
            height="198"
          />
        </figure>
      </a>
      <div className="mb-3">
        <div className="flex flex-wrap -m-1">
          {tags.map(({ slug, name }) => (
            <Tag key={slug} link={`${MEDIUM_URL}/tag/${slug}`} className="m-1">
              {name}
            </Tag>
          ))}
        </div>
      </div>
      <h3 className="h4 mb-2">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:text-gray-100 transition duration-150 ease-in-out"
        >
          {title}
        </a>
      </h3>
    </header>
    <p className="text-lg text-brand-secondary flex-grow">{subtitle}</p>
    <footer className="flex items-center mt-4">
      <div className="font-medium">
        <span className="text-gray-500">{date}</span>
      </div>
    </footer>
  </article>
);

export default BlogArticle;
