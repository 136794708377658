import React, { FC } from "react";

import BlogArticle from "./BlogArticle";
import CommonSection from "./CommonSection";
import Tag from "./Tag";
import NewButton from "./NewButton";

const BlogList: FC<{ posts: any[] }> = ({
  posts: [pinnedPost, ...restPosts],
}) => (
  <CommonSection className="!pt-32 md:!pt-40">
    {/*  Page header */}
    <div className="max-w-3xl pb-12 md:pb-20 text-center md:text-left">
      <h1 className="h1" data-aos="fade-up">
        Refreshing news for users,{" "}
        <span className="sm:whitespace-nowrap">developers and designers</span>
      </h1>
    </div>

    {/*  Featured article */}

    {pinnedPost && (
      <div className="pb-12 md:pb-20">
        <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
          <a
            href={pinnedPost.link}
            target="_blank"
            rel="noopener noreferrer"
            className="relative block group"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <div
              className="absolute inset-0 bg-brand-tertiary/5 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none rounded-[.625rem]"
              aria-hidden="true"
            ></div>
            <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out rounded-[.625rem]">
              <img
                className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                src={pinnedPost.thumbnail}
                alt={pinnedPost.title}
                width="540"
                height="303"
              />
            </figure>
          </a>
          <div data-aos="fade-left" data-aos-delay="200">
            <header>
              <div className="mb-3">
                <div className="flex flex-wrap -m-1">
                  {pinnedPost.tags.map((tag: any) => (
                    <Tag key={tag.slug} link={tag.slug} className="m-1">
                      {tag.name}
                    </Tag>
                  ))}
                </div>
              </div>

              <h3 className="h3 text-2xl lg:text-3xl mb-2">
                <a
                  href={pinnedPost.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-gray-100 transition duration-150 ease-in-out"
                >
                  {pinnedPost.title}
                </a>
              </h3>
            </header>

            <p className="text-lg text-brand-secondary flex-grow">
              {pinnedPost.description}
            </p>

            <footer className="flex items-center mt-4">
              <div className="font-medium">
                <span className="text-gray-500">{pinnedPost.date}</span>
              </div>
            </footer>
          </div>
        </article>
      </div>
    )}

    {restPosts.length > 0 && (
      <>
        {/*  Articles list */}
        <div className="max-w-sm mx-auto md:max-w-none">
          {/*  Section title */}
          <h4
            className="h4 pb-6 mb-10 border-b border-brand-tertiary/10"
            data-aos="fade-up"
          >
            Latest articles
          </h4>

          {/*  Articles container */}
          <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
            {restPosts.map((post) => (
              <BlogArticle
                key={post.id}
                link={post.link}
                image={post.thumbnail}
                tags={post.tags}
                title={post.title}
                subtitle={post.description}
                date={post.date}
              />
            ))}
          </div>
        </div>
      </>
    )}

    <nav className="flex justify-center pt-16" role="navigation">
      <NewButton
        theme="secondary"
        href="https://wigwamapp.medium.com/"
        className="flex items-center max-w-[20rem] w-full"
      >
        Read All
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="ml-1 h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
          <path d="M5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" />
        </svg>
      </NewButton>
    </nav>
  </CommonSection>
);

export default BlogList;
